// DS V2
import React, { useEffect, useRef, useState, useMemo } from 'react';
import analytics from '@analytics';
import {
  Card,
  ChartError,
  ChartLoading,
  ChartWrapper,
  Checkbox,
  Typography,
} from '@ds';
import { DateSelect } from '@ds';
import dayjs from 'dayjs';

import Link from 'next/link';
import { useRouter } from 'next/router';
import numeral from 'numeral';
import {
  ResponsiveContainer,
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  TooltipProps,
  Legend,
} from 'recharts';
import { CategoricalChartFunc } from 'recharts/types/chart/generateCategoricalChart';
import {
  MediaAnnouncement,
  RegistryImportState,
  useCurrentCompanyOverviewQuery,
  useRegistryImportStatusQuery,
} from '@/apollo/generated';
import CardWithTitles from '@/components/analysis/components/card-with-titles';
import { useCurrentCompanyProfileUser } from '@/contexts/current-company-profile-user-context';
import routes from '@/utils/routes';

const volumeColor = '#D2D6DB';
const sharePriceColor = '#0BA5EC';
const shareholderCountColor = '#12B76A';
const announcementsColor = '#000000';
const newTooltipThreshold = 150;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const AnnouncementDot = (props: any) => {
  const { cx, cy, payload } = props;
  if (payload?.announcements.length) {
    return (
      <circle
        cx={cx}
        cy={cy}
        fill={announcementsColor}
        r={4}
        stroke="black"
        strokeWidth="1"
      />
    );
  }
  return null;
};

const CustomTooltip = ({
  active,
  label,
  marketListingKey,
  payload,
  showAnnouncements,
  showSharePrice,
  showShareholderCount,
  showVolume,
}: // https://github.com/recharts/recharts/issues/2796
// eslint-disable-next-line @typescript-eslint/no-explicit-any
TooltipProps<any, any> & {
  marketListingKey: string;
  showAnnouncements: boolean;
  showSharePrice: boolean;
  showShareholderCount: boolean;
  showVolume: boolean;
}) => {
  if (active && payload?.length) {
    const shareholderCount = payload.find(
      (p) => p.dataKey === 'shareholdingsCount'
    );

    const shareholderCountValue = shareholderCount?.value
      ? numeral(shareholderCount?.value).format('0,0')
      : 'N/A';
    return (
      <div
        className="hidden max-w-[225px] space-y-1 rounded-lg bg-gray-900 p-4 md:block"
        id="custom-tooltip"
      >
        <Typography className="text-white" variant="text-caption-bold">
          {dayjs(label).format('D MMM YY')}
        </Typography>
        {showShareholderCount ? (
          <div className="flex items-center gap-2">
            <div className="h-1 w-3 bg-green-500" />
            <Typography className="text-white" variant="text-caption">
              Shareholder count: {shareholderCountValue}
            </Typography>
          </div>
        ) : null}
        {showSharePrice ? (
          <div className="flex items-center gap-2">
            <div className="h-1 w-3 bg-sky-500" />
            <Typography className="text-white" variant="text-caption">
              Share price:{' '}
              {numeral(
                payload.find((p) => p.dataKey === 'close')?.value
              ).format('0,0.00[00]')}
            </Typography>
          </div>
        ) : null}
        {showVolume ? (
          <div className="flex items-center gap-2">
            <div className="h-3 w-3 bg-gray-300" />
            <Typography className="text-white" variant="text-caption">
              Volume:{' '}
              {numeral(
                payload.find((p) => p.dataKey === 'volume')?.value
              ).format('0,0')}
            </Typography>
          </div>
        ) : null}
        {showAnnouncements &&
        payload.find((p) => p.dataKey === 'comms')?.payload?.announcements
          ?.length ? (
          <>
            <div className="flex items-center gap-2">
              <div className="h-3 w-3 rounded-full bg-white" />
              <Typography className="text-white" variant="text-caption">
                Announcements:
              </Typography>
            </div>
            <div className="ml-1 list-inside list-disc marker:text-white">
              {payload
                .find((p) => p.dataKey === 'comms')
                ?.payload?.announcements?.map((a: MediaAnnouncement) => (
                  <Link
                    key={a.id}
                    className="group inline"
                    href={routes.engagement.interactiveMedia.announcement.href(
                      marketListingKey,
                      a.id
                    )}
                    style={{ pointerEvents: 'all' }}
                  >
                    <li>
                      <Typography
                        className="inline text-white group-hover:cursor-pointer group-hover:underline"
                        variant="text-hyperlink-caption"
                      >
                        {a.header}
                      </Typography>
                    </li>
                  </Link>
                ))}
            </div>
          </>
        ) : null}
      </div>
    );
  }

  return null;
};

// This function is a modified version of recharts' internal tooltip position calculation function
const getTranslate = ({
  coordinate,
  key,
  offset,
  tooltipDimension,
  viewBox,
  viewBoxDimension,
}: {
  coordinate: {
    x: number;
    y: number;
  };
  key: 'x' | 'y';
  offset: number;
  tooltipDimension: number;
  viewBox: {
    x: number;
    y: number;
  };
  viewBoxDimension: number;
}) => {
  const restricted = coordinate[key] - tooltipDimension - offset;
  const unrestricted = coordinate[key] + offset;
  // tooltipBoundary is the coordinate of the rightmost/bottommost edge of the tooltip
  // rightmost if key === 'x'
  // bottommost if key === 'y'
  const tooltipBoundary = coordinate[key] + tooltipDimension + offset;
  // viewBoxBoundary is the coordinate of the rightmost/bottommost edge of the viewbox
  // rightmost if key === 'x'
  // bottommost if key === 'y'
  const viewBoxBoundary = viewBox[key] + viewBoxDimension;

  // If we are past the boundary, then use the restricted coordinate
  if (tooltipBoundary > viewBoxBoundary) {
    return Math.max(restricted, viewBox[key]);
  }
  // Otherwise use the unrestricted coordinate
  return Math.max(unrestricted, viewBox[key]);
};

const CompanyOverviewChart = () => {
  const {
    query: { marketListingKey },
  } = useRouter();

  const containerRef = useRef<{ current: HTMLDivElement }>();

  const [showShareholderCount, setShowShareholderCount] = useState(true);
  const [showSharePrice, setShowSharePrice] = useState(true);
  const [showVolume, setShowVolume] = useState(true);
  const [showAnnouncements, setShowAnnouncements] = useState(true);

  const [tooltipMeasurements, setTooltipMeasurements] = useState({
    height: 0,
    width: 0,
  });

  const customTooltip = document.getElementById('custom-tooltip');

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      if (entries.length > 0) {
        if (
          entries[0].contentRect &&
          entries[0].contentRect.height &&
          entries[0].contentRect.width
        ) {
          if (entries[0].contentRect.height > tooltipMeasurements.height) {
            // In some cases our resize will occur AFTER the new position of the tooltip is calculated
            // If this happens, we can potentially be using the old height to calculate position, leading to an overflow
            // if the new height is larger
            // So we do a manual update if the new height is greater
            setTooltipPosition(({ x, y }) => ({
              x: x,
              y: Math.max(
                y -
                  (entries[0].contentRect.height - tooltipMeasurements.height),
                0
              ),
            }));
          }
          setTooltipMeasurements({
            height: entries[0].contentRect.height,
            width: entries[0].contentRect.width,
          });
        }
      }
    });

    if (customTooltip) {
      resizeObserver.observe(customTooltip);
    }

    const unobserve = () => {
      if (customTooltip) {
        resizeObserver.unobserve(customTooltip);
      }
    };
    return unobserve;
  }, [customTooltip, tooltipMeasurements]);

  // tooltipPosition is a set of coordinates relative to the viewbox
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });

  const [endDate, setEndDate] = useState<Maybe<Date>>(null);
  const [startDate, setStartDate] = useState<Maybe<Date>>(null);

  React.useEffect(() => {
    analytics.track('shareholder_overview_chart_date_range_changed', {
      endDate: endDate?.toISOString(),
      page: window.location.pathname,
    });
  }, [endDate]);

  const { currentCompanyProfileUser, isPremium } =
    useCurrentCompanyProfileUser();

  const { data: registryData } = useRegistryImportStatusQuery();
  const { data, error, loading, networkStatus } =
    useCurrentCompanyOverviewQuery({
      skip: !(startDate && endDate),
      variables: {
        endDate: dayjs(endDate).format('YYYY-MM-DD'),
        startDate: dayjs(startDate).format('YYYY-MM-DD'),
      },
    });

  const shareholdingsCountAvailable = useMemo(() => {
    // Only when isPremium is true and import has finished
    return !!(
      isPremium &&
      registryData?.registryImportStatus &&
      [
        RegistryImportState.FilesImported,
        RegistryImportState.ApiImported,
      ].includes(registryData?.registryImportStatus?.state)
    );
  }, [isPremium, registryData?.registryImportStatus]);

  const maybeUpdateTooltipPosition: CategoricalChartFunc = (props) => {
    // This provides the rectangle dimensions of the viewbox of the chart
    // We need this because recharts doesn't provide the width/height of the viewbox etc
    // We call this to get the height and width of the viewbox
    const viewBoxBoundingClientRect =
      containerRef?.current?.current.getBoundingClientRect();

    // chartX and chartY are coordinates relative to the viewbox
    const { activePayload, chartX, chartY } = props;

    const xCoordinate = getTranslate({
      coordinate: {
        x: chartX as number,
        y: chartY as number,
      },
      key: 'x',
      offset: -tooltipMeasurements.width / 4,
      tooltipDimension: tooltipMeasurements.width,
      viewBox: {
        x: 0,
        y: 0,
      },
      viewBoxDimension: viewBoxBoundingClientRect?.width as number,
    });

    const yCoordinate = getTranslate({
      coordinate: {
        x: chartX as number,
        y: chartY as number,
      },
      key: 'y',
      offset: 0,
      tooltipDimension: tooltipMeasurements.height,
      viewBox: {
        x: 0,
        y: 0,
      },
      viewBoxDimension: viewBoxBoundingClientRect?.height as number,
    });

    // If the current payload has an announcement, don't change y position as we want to be able to click the announcement
    if (activePayload?.[0]?.payload?.announcements?.[0]) {
      setTooltipPosition(({ y }) => ({ x: xCoordinate as number, y: y }));
    } else if (
      Math.abs((xCoordinate as number) - tooltipPosition.x) >
      newTooltipThreshold
    ) {
      // Otherwise, if the distance between new xCoordinate and the old xCoordinate position (tooltipPosition.x) is larger thann
      // the threshold, we want to update x and y
      if (yCoordinate) {
        setTooltipPosition({
          x: xCoordinate as number,
          y: yCoordinate as number,
        });
      }
    }
  };

  const chartData = useMemo(() => {
    if (data?.currentCompanyOverview) {
      const currentCompanyOverviewData = data.currentCompanyOverview;

      // Make Announcement dot float on the top of chart
      // The Math.ceil(max * 1.1 * 100) / 100 is the same formula to calculate max domain value
      const prices = currentCompanyOverviewData.map((ele) => ele?.close ?? 0);
      const max = Math.max(...prices);

      return currentCompanyOverviewData.map((item) => {
        return {
          ...item,
          comms: item?.announcements.length
            ? Math.ceil(max * 0.3 * 100) / 100
            : null,
          volume: item?.volume ?? 0,
        };
      });
    }

    return [];
  }, [data?.currentCompanyOverview]);

  function renderChart() {
    if (loading || [1, 2, 3, 4].includes(networkStatus)) {
      return <ChartLoading />;
    }

    // TODO: should be different component
    if (error) {
      return <ChartError />;
    }

    // Calculating the min and max of shareholders count for domain
    const countList =
      data && data.currentCompanyOverview
        ? data.currentCompanyOverview.map((ele) => ele?.shareholdingsCount ?? 0)
        : [0];

    const maxCount = Math.max(...countList);
    const minCount = Math.min(...countList.filter((e) => e > 0));

    // Calculating the min and max of share price for domain
    // Some days might not have a close price
    const priceList =
      data && data.currentCompanyOverview
        ? data.currentCompanyOverview.map((ele) => ele?.close ?? 0)
        : [0];

    const maxClose = Math.max(...priceList);
    const minClose = Math.min(...priceList.filter((e) => e > 0));

    return (
      <ChartWrapper>
        <ResponsiveContainer ref={containerRef} minWidth={580}>
          <ComposedChart
            className="border-none"
            data={chartData}
            height={400}
            margin={{
              bottom: 80,
              left: 40,
              right: 40,
              top: 60,
            }}
            onMouseMove={maybeUpdateTooltipPosition}
          >
            <CartesianGrid stroke="#F3F4F6" />
            <XAxis
              axisLine={false}
              dataKey="date"
              label={{
                className: 'text-base font-semibold fill-gray-900',
                offset: -50,
                position: 'insideBottom',
                style: { textAnchor: 'middle' },
                value: 'Date',
              }}
              minTickGap={20}
              offset={10}
              scale="band"
              tick={{ stroke: '#6C737F', strokeWidth: 0.1 }}
              tickCount={5}
              tickFormatter={(v) => dayjs(v).format('D MMM YY')}
              tickLine={false}
              tickMargin={15}
            />

            {shareholdingsCountAvailable && (
              <YAxis
                axisLine={false}
                domain={[
                  Math.floor((minCount * 0.9) / 10) * 10,
                  Math.ceil((maxCount * 1.1) / 10) * 10,
                ]}
                label={{
                  angle: -90,
                  className: 'text-base font-semibold fill-gray-900',
                  offset: -30,
                  position: 'insideLeft',
                  style: { textAnchor: 'middle' },
                  value: 'Number of shareholders',
                }}
                tick={{ stroke: '#6C737F', strokeWidth: 0.1 }}
                tickFormatter={(v) => numeral(v).format('0,0')}
                tickLine={false}
                tickMargin={10}
              />
            )}

            <YAxis
              axisLine={false}
              hide={shareholdingsCountAvailable}
              label={{
                angle: -90,
                className: 'text-base font-semibold fill-gray-900',
                offset: -30,
                position: 'insideLeft',
                style: { textAnchor: 'middle' },
                value: 'Volume traded',
              }}
              orientation="left"
              tick={{ stroke: '#6C737F', strokeWidth: 0.1 }}
              tickFormatter={(v) => numeral(v).format('0,0a')}
              tickLine={false}
              tickMargin={10}
              yAxisId="volume"
            />

            <YAxis
              axisLine={{
                stroke: 'white',
              }}
              domain={() => {
                const minRange = Math.floor(minClose * 0.9 * 100) / 100;
                const maxRange = Math.ceil(maxClose * 1.1 * 100) / 100;
                return [minRange, maxRange];
              }}
              label={{
                angle: -90,
                className: 'text-base font-semibold fill-gray-900',
                offset: 90,
                position: 'insideLeft',
                style: { textAnchor: 'middle' },
                value: `Share price (${data?.currentCompanyOverview?.[0]?.currency})`,
              }}
              orientation="right"
              tick={{ stroke: '#6C737F', strokeWidth: 0.1 }}
              tickFormatter={(v) => numeral(v).format('0,0[.]00[0]')}
              tickLine={false}
              yAxisId="sharePrice"
            />

            <Tooltip
              content={
                <CustomTooltip
                  marketListingKey={marketListingKey as string}
                  showAnnouncements={showAnnouncements}
                  showSharePrice={showSharePrice}
                  showShareholderCount={
                    shareholdingsCountAvailable && showShareholderCount
                  }
                  showVolume={showVolume}
                />
              }
              position={{
                x: tooltipPosition.x,
                y: tooltipPosition.y,
              }}
            />
            <Legend
              formatter={(value: string) => {
                return (
                  <Typography
                    className="inline text-gray-700"
                    variant="text-label-sm"
                  >
                    {value}
                  </Typography>
                );
              }}
              iconSize={12}
              verticalAlign="bottom"
              wrapperStyle={{ bottom: 10 }}
            />
            {showVolume ? (
              <Bar
                barSize={20}
                dataKey="volume"
                fill={volumeColor}
                isAnimationActive={false}
                name="Volume"
                yAxisId="volume"
              />
            ) : null}
            {shareholdingsCountAvailable && showShareholderCount ? (
              <Line
                dataKey="shareholdingsCount"
                dot={false}
                isAnimationActive={false}
                legendType="plainline"
                name="Shareholder count"
                stroke={shareholderCountColor}
                strokeWidth={2}
                type="monotone"
              />
            ) : null}
            {showSharePrice ? (
              <Line
                connectNulls
                activeDot={{ r: 4, stroke: 'white', strokeWidth: 2 }}
                dataKey="close"
                dot={showAnnouncements ? <AnnouncementDot /> : false}
                isAnimationActive={false}
                legendType="plainline"
                name="Share price"
                stroke={sharePriceColor}
                strokeWidth={2}
                type="monotone"
                yAxisId="sharePrice"
              />
            ) : null}
            {showAnnouncements ? (
              <Line
                activeDot={false}
                dataKey="comms"
                dot={
                  (shareholdingsCountAvailable && showShareholderCount) ||
                  showSharePrice ? (
                    false
                  ) : (
                    <AnnouncementDot />
                  )
                }
                isAnimationActive={false}
                legendType="circle"
                name="Announcements"
                stroke={announcementsColor}
                strokeWidth={0}
                type="monotone"
                yAxisId="sharePrice"
              />
            ) : null}
          </ComposedChart>
        </ResponsiveContainer>
      </ChartWrapper>
    );
  }

  // TODO: STON001 - remove after refinitiv recovered
  if (data && data.currentCompanyOverview?.length === 0) {
    return null;
  }

  return (
    <>
      <Card transparent>
        <CardWithTitles
          rightTitle={
            <DateSelect
              endDate={endDate}
              label={<Typography></Typography>}
              setEndDate={setEndDate}
              setStartDate={setStartDate}
              startDate={startDate}
            />
          }
          subtitle={'Understand how your share registry has changed over time.'}
          title={`${currentCompanyProfileUser?.profile.name} overview`}
          titleOutsideBorder={true}
        >
          <div className="flex-wrap gap-4 font-semibold sm:flex">
            <Checkbox
              checked={showVolume ? 'yes' : 'no'}
              label={{ description: '', title: 'Volume' }}
              size="sm"
              onClick={() => setShowVolume(!showVolume)}
            ></Checkbox>
            {shareholdingsCountAvailable && (
              <Checkbox
                checked={showShareholderCount ? 'yes' : 'no'}
                label={{ description: '', title: 'Shareholder count' }}
                size="sm"
                onClick={() => setShowShareholderCount(!showShareholderCount)}
              ></Checkbox>
            )}
            <Checkbox
              checked={showSharePrice ? 'yes' : 'no'}
              label={{ description: '', title: 'Share price' }}
              size="sm"
              onClick={() => setShowSharePrice(!showSharePrice)}
            ></Checkbox>
            <Checkbox
              checked={showAnnouncements ? 'yes' : 'no'}
              label={{ description: '', title: 'Announcements' }}
              size="sm"
              onClick={() => setShowAnnouncements(!showAnnouncements)}
            ></Checkbox>
          </div>
          {renderChart()}
        </CardWithTitles>
      </Card>
    </>
  );
};

export default CompanyOverviewChart;
